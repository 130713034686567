var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "shepherd-start-1", attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { xl: "9" } },
        [
          _vm.$route.query && _vm.$route.query.invitation_target
            ? _c(
                "v-banner",
                {
                  staticClass: "mb-5 shepherd-space-banner",
                  attrs: { "single-line": "" },
                  scopedSlots: _vm._u(
                    [
                      !_vm.isOrgInvite
                        ? {
                            key: "actions",
                            fn: function () {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", elevation: "0" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          path: _vm.$route.query
                                            .invitation_target,
                                        })
                                      },
                                    },
                                  },
                                  [_vm._v("Open space")]
                                ),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c(
                    "v-avatar",
                    {
                      attrs: { slot: "icon", color: "white", size: "50" },
                      slot: "icon",
                    },
                    [
                      _c("v-icon", { attrs: { large: "", color: "success" } }, [
                        _vm._v("check_circle"),
                      ]),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "subtitle-1 font-weight-medium" }, [
                    _vm._v(_vm._s(_vm.invitationText)),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.validOrgRequests.length
            ? _c(
                "v-snackbar",
                {
                  staticClass: "dashboardNotifications",
                  attrs: { absolute: "", bottom: "", left: "", timeout: -1 },
                  model: {
                    value: _vm.notificationsSnackbar,
                    callback: function ($$v) {
                      _vm.notificationsSnackbar = $$v
                    },
                    expression: "notificationsSnackbar",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-space-between align-center",
                    },
                    [
                      _c("v-subheader", { staticClass: "secondary--text" }, [
                        _vm._v("Important Notifications"),
                      ]),
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", color: "secondary", icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.notificationsSnackbar = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("clear")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-icon",
                            { attrs: { dark: "", color: "secondary" } },
                            [_vm._v("notification_important")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "secondary--text" },
                            [
                              _vm._v(
                                _vm._s(_vm.validOrgRequests.length) +
                                  " requests require approval"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                to: { name: "user-requests" },
                                color: "primary",
                                small: "",
                              },
                            },
                            [_vm._v("Review")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("v-card-title", [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-space-between align-center w-100",
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "font-weight-bold secondary--text" },
                      [_vm._v("Dashboard")]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        !_vm.isPWA()
                          ? _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "secondary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.installPWA()
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v("mdi-lightning-bolt"),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "overline font-weight-bold" },
                                  [_vm._v("Install desktop app")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-dialog",
                          {
                            attrs: { width: "500" },
                            model: {
                              value: _vm.pwaDialog,
                              callback: function ($$v) {
                                _vm.pwaDialog = $$v
                              },
                              expression: "pwaDialog",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c(
                                  "v-alert",
                                  { attrs: { prominent: "", type: "info" } },
                                  [
                                    _c("h3", { staticClass: "text-h6 mb-2" }, [
                                      _vm._v("Browser not supported"),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        " To install the desktop application, please log into Nuvolos using Google Chrome or Microsoft Edge, the current browser does not support this functionality. "
                                      ),
                                    ]),
                                    _c(
                                      "v-card-actions",
                                      [
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { text: "" },
                                            on: {
                                              click: function ($event) {
                                                _vm.pwaDialog = false
                                              },
                                            },
                                          },
                                          [_vm._v("Close")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "d-flex align-center",
                                          attrs: {
                                            text: "",
                                            color: "secondary",
                                            href: "https://docs.nuvolos.cloud",
                                            target: "_blank",
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { small: "", left: "" } },
                                          [_vm._v("menu_book")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "overline font-weight-bold",
                                          },
                                          [_vm._v("Getting started")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "Read the documentation on how to get started with Nuvolos"
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "close-on-content-click": true,
                              "offset-y": "",
                              bottom: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "ml-1",
                                          attrs: {
                                            color: "secondary",
                                            icon: "",
                                            dark: "",
                                            loading: _vm.fetchingOrgContacts,
                                          },
                                        },
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("settings")])],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c(
                              "v-list",
                              { attrs: { dense: "", nav: "" } },
                              [
                                _vm.isOrgManager === true
                                  ? _c(
                                      "v-list-item",
                                      {
                                        attrs: {
                                          to: { name: "home-org-config" },
                                        },
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    left: "",
                                                  },
                                                },
                                                [_vm._v("settings")]
                                              ),
                                              _vm._v(" Organization settings "),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isOrgManager === true
                                  ? _c(
                                      "v-list-item",
                                      {
                                        attrs: {
                                          to: { name: "home-org-users" },
                                        },
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    left: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-account-group-outline"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" Organization users "),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: {
                                      to: {
                                        name: "home-org-tokens",
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                left: "",
                                                small: "",
                                              },
                                            },
                                            [_vm._v("mdi-key")]
                                          ),
                                          _vm._v(" Organization tokens "),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("v-divider"),
                                _vm.orgContacts.length === 0
                                  ? _c(
                                      "v-list",
                                      [
                                        _c(
                                          "v-list-item",
                                          { staticClass: "pr-10 opacity-7" },
                                          [
                                            _c(
                                              "v-list-item-icon",
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    "mdi-alert-circle-outline"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v("No contacts found"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm.$isError(_vm.orgContacts)
                                  ? _c(
                                      "v-list",
                                      { attrs: { subheader: "" } },
                                      [
                                        _c("v-subheader", [
                                          _vm._v("Organization managers"),
                                        ]),
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass:
                                              "pr-10 warning--text opacity-7",
                                          },
                                          [
                                            _c(
                                              "v-list-item-icon",
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "warning" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-alert-circle-outline"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    "Error during contact retreval"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-list",
                                      {
                                        attrs: {
                                          "two-line": "",
                                          subheader: "",
                                        },
                                      },
                                      [
                                        _c("v-subheader", [
                                          _vm._v("Organization managers"),
                                        ]),
                                        _vm._l(
                                          _vm.orgContacts,
                                          function ({ full_name, email }, j) {
                                            return _c(
                                              "v-list-item",
                                              { key: `contact-${j}` },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(_vm._s(full_name)),
                                                    ]),
                                                    _c("v-list-item-subtitle", [
                                                      _vm._v(_vm._s(email)),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item-action",
                                                  [
                                                    _c("copy-to-clipboard", {
                                                      attrs: {
                                                        textToCopy: email,
                                                        iconButton: true,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                _c("v-divider", { staticClass: "mb-2" }),
                                _c("LeaveDialog", {
                                  attrs: {
                                    levelLeavingTitle: "Leave Organization",
                                    hierarchicalLevel:
                                      _vm.nuvolosObjectTypes.ORGANIZATION,
                                    listItem: true,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c(
                "HomeDashboardCard",
                {
                  staticClass: "shepherd-recent-apps",
                  attrs: { icon: "rocket_launch", subTitle: "Quick Access" },
                },
                [
                  !_vm.$isError(_vm.recentApps)
                    ? _c(
                        "div",
                        { staticClass: "mx-n6" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mt-4" },
                            _vm._l(_vm.recentApps, function (item, idx) {
                              return _c(
                                "v-col",
                                {
                                  key: idx,
                                  attrs: { xs: "12", sm: "12", md: "4" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "px-6" },
                                    [
                                      _c("dashboard-quick-access-card", {
                                        attrs: {
                                          item: item,
                                          loading: _vm.fetchingApps,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.fetchingApps &&
                  !_vm.recentApps.length &&
                  !_vm.$isError(_vm.recentApps)
                    ? _c("div", { staticClass: "mt-4" }, [
                        _c("div", { staticClass: "caption text-center" }, [
                          _vm._v(
                            "No recent apps, add a course / project first."
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.$isError(_vm.recentApps)
                    ? _c(
                        "div",
                        { staticClass: "mt-10" },
                        [_c("dashboard-error")],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "v-row",
                { staticClass: "mt-10 pt-12 shepherd-spaces" },
                [
                  _c(
                    "v-col",
                    { attrs: { xs: "12", sm: "12", md: "4" } },
                    [
                      _c(
                        "HomeDashboardCard",
                        {
                          attrs: { icon: "school", subTitle: "Recent Courses" },
                        },
                        [
                          !_vm.fetchingSpaces
                            ? _c(
                                "div",
                                [
                                  _vm._l(
                                    _vm.recentEducationSpaces,
                                    function (item, idx) {
                                      return _c(
                                        "div",
                                        { key: idx, staticClass: "my-6" },
                                        [
                                          _c("dashboard-space-card", {
                                            attrs: { item: item },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm.educationSpaces.length > 2
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-column justify-start align-center shepherd-create-course",
                                        },
                                        [
                                          _vm.isOrgManager || _vm.isOrgFaculty
                                            ? _c(
                                                "router-link",
                                                {
                                                  staticStyle: {
                                                    "text-decoration": "none",
                                                  },
                                                  attrs: {
                                                    to: {
                                                      name: "home-add-class",
                                                      params: {
                                                        oid: _vm.$route.params
                                                          .oid,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-center align-center",
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("add")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "secondary--text",
                                                        },
                                                        [_vm._v("New Course")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.educationSpaces.length > 3
                                            ? _c(
                                                "router-link",
                                                {
                                                  staticClass: "mt-2",
                                                  staticStyle: {
                                                    "text-decoration": "none",
                                                  },
                                                  attrs: {
                                                    to: {
                                                      name: "home-education-spaces",
                                                      params: {
                                                        oid: _vm.$route.params
                                                          .oid,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-center align-center",
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("list")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "secondary--text ml-1",
                                                        },
                                                        [_vm._v("All Courses")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _c(
                                "div",
                                _vm._l(
                                  [
                                    { id: "c1", opacity: 1 },
                                    { id: "c2", opacity: 0.75 },
                                    { id: "c3", opacity: 0.5 },
                                  ],
                                  function (item) {
                                    return _c(
                                      "div",
                                      { key: item.id, staticClass: "my-6" },
                                      [
                                        _c(
                                          "div",
                                          { style: { opacity: item.opacity } },
                                          [
                                            _c("v-skeleton-loader", {
                                              staticClass: "mx-auto",
                                              attrs: {
                                                type: "list-item-avatar-two-line",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                          _vm.recentEducationSpaces.length < 3 &&
                          (_vm.isOrgManager || _vm.isOrgFaculty) &&
                          !_vm.fetchingSpaces
                            ? _c(
                                "div",
                                { staticClass: "mt-6 pa-0 py-n2" },
                                [
                                  _c(
                                    "v-list",
                                    { staticClass: "pa-0", attrs: { nav: "" } },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass:
                                            "py-2 shepherd-create-course",
                                          on: {
                                            click: function ($event) {
                                              return _vm.createSpaceAndApp(
                                                "class"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-avatar",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { "x-large": "" } },
                                                [_vm._v("add")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _c(
                                                  "h4",
                                                  {
                                                    staticClass:
                                                      "mr-2 secondary--text",
                                                  },
                                                  [_vm._v("New Course")]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.recentEducationSpaces.length &&
                          !_vm.isOrgManager &&
                          !_vm.isOrgFaculty &&
                          !_vm.fetchingSpaces
                            ? _c("div", { staticClass: "mt-4" }, [
                                _c(
                                  "div",
                                  { staticClass: "caption text-center" },
                                  [_vm._v("No courses to display.")]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { xs: "12", sm: "12", md: "4" } },
                    [
                      _c(
                        "HomeDashboardCard",
                        {
                          attrs: {
                            icon: "mdi-beaker",
                            subTitle: "Recent Projects",
                          },
                        },
                        [
                          !_vm.fetchingSpaces
                            ? [
                                _vm._l(
                                  _vm.recentResearchSpaces,
                                  function (item, idx) {
                                    return _c(
                                      "div",
                                      { key: idx, staticClass: "my-6" },
                                      [
                                        _c("dashboard-space-card", {
                                          attrs: { item: item },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _vm.researchSpaces.length > 2
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-column align-center justify-start shepherd-create-project",
                                      },
                                      [
                                        _vm.isOrgManager || _vm.isOrgFaculty
                                          ? _c(
                                              "router-link",
                                              {
                                                staticStyle: {
                                                  "text-decoration": "none",
                                                },
                                                attrs: {
                                                  to: {
                                                    name: "home-add-research-project",
                                                    params: {
                                                      oid: _vm.$route.params
                                                        .oid,
                                                    },
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-center align-center",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("add")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "secondary--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "New Research Project"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.researchSpaces.length > 3
                                          ? _c(
                                              "router-link",
                                              {
                                                staticClass: "mt-2",
                                                staticStyle: {
                                                  "text-decoration": "none",
                                                },
                                                attrs: {
                                                  to: {
                                                    name: "home-research-spaces",
                                                    params: {
                                                      oid: _vm.$route.params
                                                        .oid,
                                                    },
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("list")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "secondary--text ml-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "All Research Projects"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._l(
                                [
                                  { id: "r1", opacity: 1 },
                                  { id: "r2", opacity: 0.75 },
                                  { id: "r3", opacity: 0.5 },
                                ],
                                function (item) {
                                  return _c(
                                    "div",
                                    { key: item.id, staticClass: "my-6" },
                                    [
                                      _c(
                                        "div",
                                        { style: { opacity: item.opacity } },
                                        [
                                          _c("v-skeleton-loader", {
                                            staticClass: "mx-auto",
                                            attrs: {
                                              type: "list-item-avatar-two-line",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }
                              ),
                          _vm.recentResearchSpaces.length < 3 &&
                          (_vm.isOrgManager || _vm.isOrgFaculty) &&
                          !_vm.fetchingSpaces
                            ? _c(
                                "div",
                                { staticClass: "mt-6 pa-0 py-n2" },
                                [
                                  _c(
                                    "v-list",
                                    { staticClass: "pa-0", attrs: { nav: "" } },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass:
                                            "py-2 shepherd-create-project",
                                          on: {
                                            click: function ($event) {
                                              return _vm.createSpaceAndApp(
                                                "research"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-avatar",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { "x-large": "" } },
                                                [_vm._v("add")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _c(
                                                  "h4",
                                                  {
                                                    staticClass:
                                                      "mr-2 secondary--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "New Research Project"
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.recentResearchSpaces.length &&
                          !_vm.isOrgManager &&
                          !_vm.isOrgFaculty &&
                          !_vm.fetchingSpaces
                            ? _c("div", { staticClass: "mt-4" }, [
                                _c(
                                  "div",
                                  { staticClass: "caption text-center" },
                                  [_vm._v("No research spaces to display.")]
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { xs: "12", sm: "12", md: "4" } },
                    [
                      _c(
                        "HomeDashboardCard",
                        {
                          attrs: {
                            icon: "mdi-database",
                            subTitle: "Recent Datasets",
                          },
                        },
                        [
                          !_vm.fetchingSpaces
                            ? _c(
                                "div",
                                [
                                  _vm._l(
                                    _vm.recentDatasetSpaces,
                                    function (item, idx) {
                                      return _c(
                                        "div",
                                        { key: idx, staticClass: "my-6" },
                                        [
                                          _c("dashboard-space-card", {
                                            attrs: { item: item },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm.datasetSpaces.length > 2
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-column align-center justify-start",
                                        },
                                        [
                                          _vm.isOrgManager || _vm.isOrgFaculty
                                            ? _c(
                                                "router-link",
                                                {
                                                  staticStyle: {
                                                    "text-decoration": "none",
                                                  },
                                                  attrs: {
                                                    to: {
                                                      name: "home-add-dataset",
                                                      params: {
                                                        oid: _vm.$route.params
                                                          .oid,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center",
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("add")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "secondary--text",
                                                        },
                                                        [_vm._v("New Dataset")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.datasetSpaces.length > 3
                                            ? _c(
                                                "router-link",
                                                {
                                                  staticClass: "mt-2",
                                                  staticStyle: {
                                                    "text-decoration": "none",
                                                  },
                                                  attrs: {
                                                    to: {
                                                      name: "home-dataset-spaces",
                                                      params: {
                                                        oid: _vm.$route.params
                                                          .oid,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center",
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("list")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "secondary--text ml-1",
                                                        },
                                                        [_vm._v("All Datasets")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _c(
                                "div",
                                _vm._l(
                                  [
                                    { id: "d1", opacity: 1 },
                                    { id: "d2", opacity: 0.75 },
                                    { id: "d3", opacity: 0.5 },
                                  ],
                                  function (item) {
                                    return _c(
                                      "div",
                                      { key: item.id, staticClass: "my-6" },
                                      [
                                        _c(
                                          "div",
                                          { style: { opacity: item.opacity } },
                                          [
                                            _c("v-skeleton-loader", {
                                              staticClass: "mx-auto",
                                              attrs: {
                                                type: "list-item-avatar-two-line",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                          _vm.recentDatasetSpaces.length < 3 &&
                          (_vm.isOrgManager || _vm.isOrgFaculty) &&
                          !_vm.fetchingSpaces
                            ? _c(
                                "div",
                                { staticClass: "mt-6 pa-0 py-n2" },
                                [
                                  _c(
                                    "v-list",
                                    { staticClass: "pa-0", attrs: { nav: "" } },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "py-2",
                                          on: {
                                            click: function ($event) {
                                              return _vm.createSpaceAndApp(
                                                "dataset"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-avatar",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { "x-large": "" } },
                                                [_vm._v("add")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _c(
                                                  "h4",
                                                  {
                                                    staticClass:
                                                      "mr-2 secondary--text",
                                                  },
                                                  [_vm._v("New Dataset")]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.recentDatasetSpaces.length &&
                          !_vm.isOrgManager &&
                          !_vm.fetchingSpaces
                            ? _c("div", { staticClass: "mt-4" }, [
                                !_vm.userInfo.is_trial_user
                                  ? _c(
                                      "div",
                                      { staticClass: "caption text-center" },
                                      [
                                        _vm._v(
                                          " No datasets enabled, ask your organization administrator to onboard a dataset. "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "caption text-center" },
                                      [
                                        _vm._v(
                                          "There are no datasets enabled for the trial"
                                        ),
                                      ]
                                    ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.userMetadata.latestOrg
                ? _c("TheOrgSelectorDialog")
                : _vm._e(),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "450px" },
                  model: {
                    value: _vm.showWelcomeDialog,
                    callback: function ($$v) {
                      _vm.showWelcomeDialog = $$v
                    },
                    expression: "showWelcomeDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "justify-center text-h6" },
                        [_vm._v("Your quick start guide")]
                      ),
                      _c("v-card-text", [
                        _c("p", [
                          _vm._v(
                            "Hi " +
                              _vm._s(_vm.userInfo && _vm.userInfo.given_name) +
                              ","
                          ),
                        ]),
                        _c(
                          "p",
                          [
                            _vm._v(
                              " We appreciate your interest in Nuvolos! Check out this quick tour to familiarise yourself with the interface. Don't hesitate to contact the Nuvolos team via "
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "mx-1",
                                attrs: {
                                  color: "primary",
                                  fab: "",
                                  "x-small": "",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "messenger",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      viewBox: "0 0 28 32",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" Messenger. "),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "d-flex flex-column align-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "width-8",
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.startTour()
                                },
                              },
                            },
                            [_vm._v("Start")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-3",
                              attrs: { plain: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.skipWelcomeTour()
                                },
                              },
                            },
                            [_vm._v("Skip")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }